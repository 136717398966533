import React from 'react';
import {Link} from 'gatsby';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import {colors} from '../../../style/constants';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    cursor: 'pointer',
    padding: 10,
    '& a > .clickable': {
      boxShadow: 'none',
      transitionDuration: '0.25s',
      '& > *': {
        padding: 20,
        borderRadius: 10,
        height: 100,
        [theme.breakpoints.down('xs')]: {
          height: 75,
        },
      },
      '& > :first-child': {
        background: colors.TURQUOISE,
        borderRadius: '10px 0 0 10px',
        '& > svg': {
          width: 60,
          height: 60,
          [theme.breakpoints.down('xs')]: {
            width: 35,
            height: 35,
          },
          '& > *': {fill: colors.WHITE + '!important'}
        }
      },
      '& > :last-child': {
        borderRadius: '0 10px 10px 0',
        background: colors.WHITE
      }
    },
    '& a > .clickable:hover': {
      transform: 'scale(1.025)',
      transitionDuration: '0.25s'
    }
  },
}));

const Card = (props) => {
  const classes = useStyles();
  return (
    <Grid onClick={props.onClick} className ={classes.wrapper} item xs={12} sm={6}>
      { props.industry.node.contentful_id === "2ucuM8w25zf95SjOxURGBL" ? 
      <a href="https://www.micotv.ca/" target="_blank" rel="noopener noreferrer">
        <Grid className='clickable' container alignItems='center'>
          <Grid className='clickable' container item xs={3} sm={2} alignItems='center'>{props.icon}</Grid>
          <Grid className='clickable' container item xs={9} sm={10} alignItems='center'><h4>{props.industry.node.name}</h4></Grid>
        </Grid>
      </a>
      :
      <Link to={props.industry.node.permalink}>
        <Grid className='clickable' container alignItems='center'>
          <Grid className='clickable' container item xs={3} sm={2} alignItems='center'>{props.icon}</Grid>
          <Grid className='clickable' container item xs={9} sm={10} alignItems='center'><h4>{props.industry.node.name}</h4></Grid>
        </Grid>
      </Link>
      }
    </Grid>
  );
}

export default Card;