import React from 'react';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import {colors} from '../../../style/constants';
import Card from './card';
import {Pharmacies, Finance, Factories, Glasses} from '../../../components/svgs/industries';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    background: colors.LIGHT_GREY,
    textAlign: 'center',
    '& p': {
      marginTop: 15
    }
  },
  channels: {
    padding: '50px 0',
    [theme.breakpoints.down('xs')]: {
      padding: '20px 0',
    },
  }
}));

const Channels = (props) => {
  const renderCards = () => {
    return props.channels.edges.map((industry, index) => {
      const renderIcon = () => {
        if (industry.node.contentful_id === "PWF1ScawoaaxnKIgjOtuc") {
          return <Pharmacies />
        }
        if (industry.node.contentful_id === "6ZaDyyZZ4mBcj8sAQtdw4s") {
          return <Finance />
        }
        if (industry.node.contentful_id === "626he1WN2uKETazng2SuCd") {
          return <Factories />
        }
        if (industry.node.contentful_id === "2ucuM8w25zf95SjOxURGBL") {
          return <Glasses />
        }
      }

      return(
        <Card key={index} industry={industry} icon={renderIcon()}/>
      )
    })
  }

  const classes = useStyles();
  return (
    <>
      <section id='turnkey-content' className={classes.wrapper + ' clipped'}>
        <Container>
          <Grid container direction='column' alignItems='center'>
            <h2>{props.lang === 'en-CA' ? 'Turnkey content for these industries' : 'Du contenu clé en main pour ces secteurs'}</h2>
            <p>{props.lang === 'en-CA' ? 'Our editorial team takes care of providing new and varied content directly to your screens.' : 'Notre équipe de rédaction s\'occupe de vous offrir du contenu nouveau et varié directement à vos écrans.'}</p>
            <Grid className={classes.channels} container>
              {renderCards()}
            </Grid>
          </Grid>
        </Container>
      </section>
    </>
  );
}

export default Channels;