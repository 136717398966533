import React, {useState} from 'react';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import {colors} from '../../../style/constants';
import List from './list';
import Card from './card';
import Presentation from '../presentation';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    background: colors.LIGHT_GREY,
    textAlign: 'center',
    '& p': {
      marginTop: 15
    }
  },
  templates: {
    padding: '60px 0',
    [theme.breakpoints.down('xs')]: {
      padding: '30px 0',
    },
  }
}));

const Templates = (props) => {
  const [state, setState] = useState({
    activeCategory: 0,
    activeTemplate: false
  })

  const handleCategoryClick = (newActiveCategory) => {
    setState({
      activeCategory: newActiveCategory,
      activeTemplate: false,
    })
  }

  const renderCards = () => {
    return props.categories.edges[state.activeCategory].node.templates.map((card, index) => {
      const handleTemplateClick = () => {
        setState({
          activeCategory: state.activeCategory,
          activeTemplate: index,
        })
      }
      return(
        <Card key={index} card={card} onClick={handleTemplateClick}/>
      )
    })
  }

  const classes = useStyles();
  return (
    <>
      <section id='templates' className={classes.wrapper + ' clipped'}>
        <Container>
          <Grid container direction='column' alignItems='center'>
            <h2>{props.lang === 'en-CA' ? 'Templates simplifying your life' : 'Des gabarits vous simplifiant la vie'}</h2>
            <p>{props.lang === 'en-CA' ? 'Choose from our wide range of choices to distribute your content. \n Here are a few with different uses for each.' : 'Choisissez parmi notre gamme de gabarits afin de diffuser votre contenu. \n En voici quelques-uns avec différentes utilités.'}</p>
            <Grid className={classes.templates} container>
              <Grid item xs={12} md={3}>
                <List active={state.activeCategory} categories={props.categories} onClick={handleCategoryClick}/>
              </Grid>
              <Grid container item xs={12} md={9}>
                {renderCards()}
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </section>
      {state.activeTemplate !== false ? <Presentation datas={props.categories.edges[state.activeCategory].node.templates[state.activeTemplate]} type='template'/>
        : <div id='template'></div>}
    </>
  );
}

export default Templates;