import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { colors } from '../../../style/constants';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    textAlign: 'left',
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      justifyContent: 'center',
      flexWrap: 'wrap',
      marginBottom: 10,
    },
    '& li': {
      marginBottom: 10,
      [theme.breakpoints.down('sm')]: {
        float: "left",
        padding: 10,
        marginBottom: 0,
      },
      '& button': {
        textAlign: 'left',
        fontSize: '1.375em',
        fontWeight: 500,
        color: colors.GREY,
        cursor: 'pointer',
        fontFamily: 'Poppins, sans-serif',
        background: 'none',
        padding: '0 10px 0 0',
        [theme.breakpoints.down('sm')]: {
          border: colors.GREY + ' solid 1px',
          padding: 10,
          borderRadius: 10,
        },
      }
    }
  },
  active: {
    color: colors.ATT_BLUE + '!important',
    [theme.breakpoints.down('sm')]: {
      borderColor: colors.ATT_BLUE + '!important'
    },
  }
}));

const List = (props) => {
  const classes = useStyles();

  const renderListItem = () => {
    return props.categories.edges.map((category, index) => {
      const handleCategoryClick = () => {
        props.onClick(index);
      }
      return(
        <li key={index}><button className={props.active === index ? classes.active : ''} onClick={handleCategoryClick}>{category.node.name}</button></li>
      )
    })
  };
  
  return (
    <ul className={classes.wrapper}>
      {renderListItem()}
    </ul>
  );
}

export default List;