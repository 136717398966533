import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import {colors} from '../../../style/constants';
import { GatsbyImage } from "gatsby-plugin-image";
import AnchorLink from 'react-anchor-link-smooth-scroll';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    cursor: 'pointer',
    padding: 10,
    '& .gatsby-image-wrapper': {
      height: 144,
      width: '100%',
      borderRadius: '10px 10px 0 0',
      [theme.breakpoints.down('xs')]: {
        height: 80,
      },
    },
    '& > a > .clickable': {
      transitionDuration: '0.25s',
      boxShadow: 'none',
      '&:active > .clickable': {
        boxShadow: 'none',
      },
      '& .gatsby-image-wrapper': {
        zIndex: -1,
      },
      '& > :last-child': {
        background: colors.WHITE,
        borderRadius: '0 0 10px 10px',
        padding: 20,
        boxSizing: 'border-box',
        height: 72,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        [theme.breakpoints.down('xs')]: {
          height: 60,
        },
      },
      '&:hover': {
        transform: 'scale(1.05)',
        transitionDuration: '0.25s'
      }
    } 
  },
}));

const Card = (props) => {
  const classes = useStyles();
  return (
    <Grid onClick={props.onClick} className ={classes.wrapper} item xs={6} md={4}>
      <AnchorLink offset='160' href='#template'>
        <div className='clickable'>
          <GatsbyImage className='clickable' image={props.card.examples[0].gatsbyImageData} alt=""/>
          <div className='clickable'>
            <h4>{props.card.name}</h4>
          </div>
        </div>
      </AnchorLink>
    </Grid>
  );
}

export default Card;