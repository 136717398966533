import React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import {graphql, useStaticQuery} from 'gatsby';
import Content from '../layouts/Content/';

const ContentPageFr = () => {
  const data = useStaticQuery(graphql`
    query contentPageDataFr {
      image: file(relativePath: { eq: "content/header.jpg"}) {
        id
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      allContentfulCategorieDeGabarit(sort: {fields: name, order: ASC}, filter: {node_locale: {eq: "fr-CA"}}) {
        edges {
          node {
            name
            templates {
              name
              description {
                description
              }
              examples {
                gatsbyImageData(layout: CONSTRAINED)
              }
            }
          }
        }
      }
      allContentfulSecteurs(filter: {handkeyContent: {eq: true}, node_locale: {eq: "fr-CA"}}, sort: {fields: createdAt}) {
        edges {
          node {
            name
            permalink
            contentful_id
          }
        }
      }
    }
  `)

  return (
    <Layout 
    lang="fr-CA" 
    switchLink='/en/content' 
    slider={true} 
    headerImage={data.image.childImageSharp} 
    headerImageAlt="Contenu Attractif pour Affichage dynamique"
    title="Des gabarits prêts à personnaliser et contenu clé en main" 
    subTitle={"Choisissez des gabarits selon vos besoins ou adaptez-les aux couleurs de votre entreprise. Optez aussi pour du contenu clé en main via nos canaux spécialisés."}
    anchorLink="#templates"
    anchorLinkText="Gabarits"
    anchorLink2="#turnkey-content"
    anchorLinkText2="Clé en main"
    >
      <Seo 
      title="Gabarits offerts dans l'application d'affichage dynamqiue"
      description="Choisissez des gabarits d'affichage selon vos besoins ou adaptez-les aux couleurs de votre entreprise. Grande gamme de choix afin de diffuser votre contenu de manière optimale."
       />
      <Content lang="fr-CA" templateCategories={data.allContentfulCategorieDeGabarit} channels={data.allContentfulSecteurs} />
    </Layout>
  )
}

export default ContentPageFr
