import React, {useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import { GatsbyImage } from "gatsby-plugin-image";

const useStyles = makeStyles((theme) => ({
  screenshots: {
    border: '3px black solid',
    borderRadius: 3,
    width: 'calc(100%/3 - 15px)',
    '& .gatsby-image-wrapper': {
      width: '100%'
    },
  },
  display: {
    marginBottom: 30,
    border: '10px black solid',
    boxShadow: '-20px 20px 20px 0px rgba(0,0,0,0.5)',
    boxSizing: 'border-box',
    borderRadius: 10,
    '& .gatsby-image-wrapper': {
      width: '100%'
    },
    [theme.breakpoints.down('xs')]: {
      border: '5px black solid',
      borderRadius: 5,
      boxShadow: '-10px 10px 10px 0px rgba(0,0,0,0.5)',
    },
  },
  infos: {
    paddingLeft: 30,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 0,
      marginTop: 30,
    },
    '& h2': {
      marginTop: 15
    },
    '& .gatsby-image-wrapper': {
      borderRadius: 10,
      width: '90px'
    },
    '& p': {
      marginTop: 30,
      marginLeft: 15
    }
  }
}));

const Presentation = (props) => {
  const classes = useStyles();
  
  const [screenshot, setScreenshot] = useState('0');

  const renderExamples = () => {
    return props.datas.examples.slice(0, 3).map((example, index) => {
      const handleHover = () => {
        setScreenshot(index);
      }
      return(
        <Grid className={classes.screenshots} key={index} item onMouseEnter={handleHover}>
          <GatsbyImage image={example.gatsbyImageData} alt=""/>
        </Grid>
      )
    })
  }

  return (
    <section id={props.type}>
      <Container>
        <Grid container justifyContent='flex-start'>
          <Grid item container xs={12} md={6} justifyContent='flex-start' direction='column' alignItems='flex-start'>
            <Grid className={classes.display} container>
              <GatsbyImage image={props.datas.examples[screenshot].gatsbyImageData} alt=""/>
            </Grid>
            {props.datas.examples.length > 1 ?
            <Grid container justifyContent='space-between'>
              {renderExamples()}
            </Grid>
            : ''}
          </Grid>
          <Grid className={classes.infos} container item xs={12} md={6} direction='column'>
            <Grid container direction='column'>
              <h2>{props.datas.name}</h2>
            </Grid>
            <p>{props.datas.description.description}</p>
          </Grid>
        </Grid>
      </Container>
    </section>
  );
}

export default Presentation;