import React from 'react';
import Templates from './Templates/';
import Channels from './Channels/';

const Content = (props) => {
  return (
    <>
     <Templates lang={props.lang} categories={props.templateCategories}/>
     <Channels lang={props.lang} channels={props.channels} />
    </>
  );
}

export default Content;